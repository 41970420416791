import axios from "axios";
import { useCookies } from "vue3-cookies";
import i18n from "@/core/plugins/i18n";
import router from "@/router";
import { useAppsStore } from "@/stores/app";

const { cookies } = useCookies();
const baseURL = import.meta.env.VITE_APP_API_URL;
const language = i18n.global.locale.value;


const axiosInstance = (app) => {
  const notify = app.config.globalProperties.$notificationMessage;

  app.config.globalProperties.$http = axios.create({
    baseURL: baseURL,
    headers: {
      "accept-language": language,
      "Content-Type": "application/json;  charset=UTF-8",
    },
  });

  const $http = app.config.globalProperties.$http;

  $http.interceptors.request.use((request) => {
    const authToken = request.headers.Authorization;
    if (!authToken) {
      const loginData = cookies.get("safarfy-user-login-data", {
        parseJSON: true,
      });

      if (loginData) {
        request.headers.Authorization = `Bearer ${loginData?.token?.access_token}`
      }
    }

    return request;
  });

  $http.interceptors.response.use(
    (response) => {
      const appsStore = useAppsStore();

      appsStore.setErrors({});
      return response;
    },
    (error) => {
      switch (error.response?.status) {
        case 401:
          cookies.remove("safarfy-user-login-data");
          cookies.remove("safarfy-user-login-data", "/");
          cookies.remove("safarfy-user-login-data", "/ar");
          cookies.remove("safarfy-user-login-data", "/en");
          router.push({
            name: "login",
            params: {
              lang: language,
            },
          });
          break;
        case 400:
          notify({
            title: error.response.data.message,
            type: "fail",
          });
          break;
        case 403:
        case 404:
          router.push({
            name: "404",
            params: {
              lang: language,
            },
          });
          // notify({
          //   title: app.config.globalProperties.$t("error_messages.404"),
          //   type: "fail",
          // });
          break;
        case 422:
          const errors = error.response.data.data;           
          const firstError = Object.values(errors)[0][0];
          notify({
            title: firstError,
            type: "fail",
          });         
          break;
        default:
          notify({
            title: app.config.globalProperties.$t("error_messages.500"),
            type: "fail",
          });
      }
      //handel errors here
      return Promise.reject(error);
    }
  );
  return app.config.globalProperties.$http;
};
export default axiosInstance;
