import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import pages from "./pages";
import i18n from "@/core/plugins/i18n";
import { useCookies } from "vue3-cookies";
import { permissions } from '@/enums/permissions.enum';
import { useAccountStore } from '@/stores';
import { watch } from "vue";
import { socialTrakingEvents } from "@/core/helpers/metaPixel.js";

const { cookies } = useCookies();
const routes = [
  {
    path: "/",
    redirect: `/${i18n.global.locale.value}`,
  },
  {
    path: "/:lang",
    children: [
      ...pages,
      {
        path: "/:lang/:pathMatch(.*)*",
        redirect: `/${i18n.global.locale.value}/404`,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 80,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        left: 0,
        behavior: "smooth",
      };
    }
  },
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    });
  };
}




router.beforeResolve((to, from, next) => {
  const langs = ["ar", "en"];
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", i18n.global.locale.value);
  }


  // reset config to initial state
  if (langs.includes(to.params.lang)) {
    i18n.global.locale.value = to.params.lang;
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", to.params.lang);
    getHTMLTag.setAttribute("dir", to.params.lang == "ar" ? "rtl" : "ltr");


    // current page view title
    if (to.params.lang == "ar") {
      document.title = `${import.meta.env.VITE_APP_NAME} ${i18n.global ? ` - ${i18n.global.t(to.meta.pageTitle)}` : ""
        }`;
    } else {
      document.title = `${i18n.global ? `${i18n.global.t(to.meta.pageTitle)} - ` : ""
        } ${import.meta.env.VITE_APP_NAME}`;
    }

    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);

      return middleware[0]({ ...context, next: nextMiddleware });
    }
    return next();
  } else {
    if (to.name)
      next({ name: to.name, params: { lang: i18n.global.locale.value } });
    else next({ name: "home", params: { lang: i18n.global.locale.value } });
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
  // Remove initial loading
  const accountStore = useAccountStore();

  if (to.name && (to.name != 'countries-details' && to.name != 'packages-details' && to.name != 'packages-details-hotel' && to.name != 'tickets-details' && to.name != 'packages-details-hotel')) {
    socialTrakingEvents('page_view', {
      fb: {
        content_name: to.name,
      },
      st: {
        content_name: to.name,
      },
      ttq: {
        content_name: to.name,
      },
      ga: {
        content_name: to.name,
      },
    });
  }

  if (to.meta.pagePermissions) {

    if (to.meta.pagePermissions.create && !accountStore.getAccountPermissions.includes(to.meta.pagePermissions.create)) {
      removeElementsFromDom('button-header-create');
    }

    if (to.meta.pagePermissions.read && !accountStore.getAccountPermissions.includes(to.meta.pagePermissions.read)) {
      removeElementsFromDom('permission-show-detail');
    }

    if (to.meta.pagePermissions.delete && !accountStore.getAccountPermissions.includes(to.meta.pagePermissions.delete)) {
      removeElementsFromDom('permission-delete-item');
    }

    if (to.meta.pagePermissions.edit && !accountStore.getAccountPermissions.includes(to.meta.pagePermissions.edit)) {
      removeElementsFromDom('permission-edit-item');
    }

    if (to.meta.pagePermissions.status && !accountStore.getAccountPermissions.includes(to.meta.pagePermissions.status)) {
      removeElementsFromDom('permission-status-item');
    }
  }
});


export function removeElementsFromDom(className) {
  setTimeout(() => {
    const elements = document.getElementsByClassName(className);
    Array.from(elements).forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }, 0);
}

export { routes }; // Export routes for external usage

export default router;


