const loggedEvents = new Set();

export const trackEvent = (eventName, eventData = {}) => {
    if (typeof fbq === "undefined") {
        // console.warn("Meta Pixel is not initialized.");
        return;
    }
    fbq("track", eventName, eventData);

};


export const trackSnapEvent = (eventName, eventData = {}) => {
    if (typeof snaptr === "undefined") {
        // console.warn("snaptr Pixel is not initialized.");
        return;
    }

    const eventKey = `${eventName}:${window.location.href}`;

    if (loggedEvents.has(eventKey)) {
        // console.warn(`Event "${eventName}" is already logged for this URL.`);
        return;
    }

    snaptr("track", eventName, eventData);
    loggedEvents.add(eventKey);
};


export const trackTtqEvent = (eventName, eventData = {}) => {
    if (typeof ttq === "undefined") {
        // console.warn("ttq Pixel is not initialized.");
        return;
    }
    ttq.track(eventName, eventData);
};

// Google Analytics tracking function
export const trackGtagEvent = (eventName, eventData = {}) => {
    if (typeof gtag === "undefined") {
        // console.warn("Google Analytics is not initialized.");
        return;
    }
    gtag("event", eventName, eventData);

};

export const initSnapEvent = (eventName, eventData = {}) => {
    if (typeof snaptr === "undefined") {
        // console.warn("Meta Pixel is not initialized.");
        return;
    }
    snaptr('init', "e7e20c60-6189-4a2c-9fc2-aba85281d7cd",
        {
            ...eventData,
            'user_hashed_email': hashEmail(eventData.user_email),
            'user_hashed_phone_number': hashEmail(eventData.user_email)
        });

};



export const socialTrakingEvents = (name, eventData) => {
    let eventName = ''
    if (name == 'signup') {
        trackEvent('Signup', eventData.fb); // Facebook
        trackSnapEvent("SIGN_UP", eventData.st); // Snapchat
        trackTtqEvent("Signup", eventData.ttq); // TikTok
        trackGtagEvent('web_sign_up', eventData.ga); // Google Analytics
    }
    else if (name == 'login') {
        trackEvent('Signin', eventData.fb); // Facebook
        trackSnapEvent("LOGIN", eventData.st); // Snapchat
        trackTtqEvent("Signin", eventData.ttq); // TikTok
        trackGtagEvent('web_login', eventData.ga); // Google Analytics
    }
    else if (name == 'content_view') {
        trackEvent('ViewContent', eventData.fb); // Facebook
        trackSnapEvent("VIEW_CONTENT", eventData.st); // Snapchat
        trackTtqEvent("ViewContent", eventData.ttq); // TikTok
        trackGtagEvent('web_view_item', eventData.ga); // Google Analytics
    }
    else if (name == 'add_to_cart') {
        trackEvent('AddToCart', eventData.fb); // Facebook
        trackSnapEvent("ADD_CART", eventData.st); // Snapchat
        trackTtqEvent("AddToCart", eventData.ttq); // TikTok
        trackGtagEvent('web_add_to_cart', eventData.ga); // Google Analytics

    }
    else if (name == 'purchase') {
        trackEvent('Purchase', eventData.fb); // Facebook
        trackSnapEvent("PURCHASE", eventData.st); // Snapchat
        trackTtqEvent("CompletePayment", eventData.ttq); // TikTok
        trackGtagEvent('web_purchase', eventData.ga); // Google Analytics

    } else if (name == 'page_view') {
        trackEvent('PageView', eventData.fb); // Facebook
        trackSnapEvent("PAGE_VIEW", eventData.st); // Snapchat
        trackTtqEvent("PageView", eventData.ttq); // TikTok
        trackGtagEvent('web_page_view', eventData.ga); // Google Analytics
    }
};


