import { h, resolveComponent } from "vue";
import middleware from "../middleware/middleware";
import authMiddleware from "../middleware/auth";
import auth from "./auth";
import profile from "./profile";


export default [
    {
        path: "",
        component: () => import("@/layouts/DefaultLayout.vue"),
        children: [
            {
                path: "",
                name: "home",
                component: () => import("@/views/home/Index.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "navbar.Home",
                    breadcrumbs: [],
                },
            },
            {
                path: "404",
                name: "404",
                component: () => import("@/views/authentication/Error404.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "navbar.404",
                    breadcrumbs: [],
                },
            },
            // {
            //     path: "about",
            //     name: "about",
            //     component: () => import("@/views/About.vue"),
            //     meta: {
            //         middleware: [middleware],
            //         pageTitle: "navbar.About Safarfy",
            //         breadcrumbs: [],
            //     },
            // },
            {
                path: "privacy-policy",
                name: "privacy-policy",
                component: () => import("@/views/PrivacyPolicy.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "settings.Privacy policy",
                    breadcrumbs: [],
                },
            },
            {
                path: "terms",
                name: "terms",
                component: () => import("@/views/Terms.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "settings.Terms & Conditions",
                    breadcrumbs: [],
                },
            },
            {
                path: "contact-us",
                name: "contact-us",
                component: () => import("@/views/ContactUs.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "navbar.Contact Us",
                    breadcrumbs: [],
                },
            },
            {
                path: "search",
                name: "search",
                component: () => import("@/views/Search.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "common.Search",
                    breadcrumbs: [],
                },
            },
            {
                path: "faq",
                name: "faq",
                component: () => import("@/views/FAQ.vue"),
                meta: {
                    middleware: [middleware],
                    pageTitle: "navbar.FAQ",
                    breadcrumbs: [],
                },
            },

            {
                path: "countries",
                children: [
                    {
                        path: "",
                        name: "countries",
                        component: () => import("@/views/countries/Index.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.countries",
                            breadcrumbs: [],
                        },
                    },
                    {
                        path: "details/:id",
                        name: "countries-details",
                        component: () => import("@/views/countries/Details.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.countries",
                            breadcrumbs: [],
                        },
                    }
                ]
            },

            {
                path: "packages",
                children: [
                    {
                        path: "",
                        name: "packages",
                        component: () => import("@/views/packages/Index.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.Tourist Packages",
                            breadcrumbs: [],
                        },
                    },
                    {
                        path: "details/:id",
                        name: "packages-details",
                        component: () => import("@/views/packages/Details.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.Tourist Packages",
                            breadcrumbs: [],
                        },
                    },
                    {
                        path: "details/:packageId/:id/hotel/:hotelId",
                        name: "packages-details-hotel",
                        component: () => import("@/views/hotels/Details.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.Tourist Packages",
                            breadcrumbs: [],
                        },
                    }
                ]
            },
            {
                path: "tickets",
                children: [
                    {
                        path: "",
                        name: "tickets",
                        component: () => import("@/views/tickets/Index.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.Tourist Packages",
                            breadcrumbs: [],
                        },
                    },
                    {
                        path: "details/:id",
                        name: "tickets-details",
                        component: () => import("@/views/tickets/Details.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.Tourist Packages",
                            breadcrumbs: [],
                        },
                    },
                    {
                        path: "details/:packageId/:id/hotel/:hotelId",
                        name: "packages-details-hotel",
                        component: () => import("@/views/hotels/Details.vue"),
                        meta: {
                            middleware: [middleware],
                            pageTitle: "navbar.Tourist Packages",
                            breadcrumbs: [],
                        },
                    }
                ]
            },
            {
                path: "bookings",
                children: [
                    {
                        path: "",
                        name: "bookings",
                        component: () =>
                            import("@/views/profile/bookings/Index.vue"),
                        meta: {
                            pageTitle: "navbar.Bookings",
                        },
                    },
                    {
                        path: "details/:id",
                        name: "bookings-details",
                        component: () =>
                            import("@/views/profile/bookings/Details.vue"),
                        meta: {
                            pageTitle: "navbar.Bookings",
                        },
                    },
                    {
                        path: "details/:id/package",
                        name: "bookings-details-package",
                        component: () =>
                            import("@/views/profile/bookings/DetailsPackage.vue"),
                        meta: {
                            pageTitle: "navbar.Bookings",
                        },
                    },

                    {
                        path: "details/:id/package/:hotelId",
                        name: "bookings-details-package-hotel",
                        component: () =>
                            import("@/views/profile/bookings/DetailsPackageHotel.vue"),
                        meta: {
                            pageTitle: "navbar.Bookings",
                        },
                    },
                ]
            },
            {
                path: "cart",
                name: "cart",
                component: () => import("@/views/cart/index.vue"),
                meta: {
                    middleware: [authMiddleware],
                    pageTitle: "cart.title",
                },
            },
        ]
    },
    {
        path: "",
        component: () => import("@/layouts/PaymentLayout.vue"),
        meta: {
        },
        children: [
            {
                path: "cart/payment",
                name: "cart-payment",
                component: () =>
                    import("@/views/cartPayment/Index.vue"),
                meta: {
                    middleware: [authMiddleware],
                    pageTitle: "common.Payment and completion of the order",
                },
            },
            {
                path: "tickets/payment",
                name: "tickets-payment",
                component: () =>
                    import("@/views/cartPayment/Index.vue"),
                meta: {
                    middleware: [authMiddleware],
                    pageTitle: "common.Payment and completion of the order",
                },
            }
        ]
    },
    {
        path: "",
        component: () => import("@/layouts/PaymentLayout.vue"),
        meta: {
        },
        children: [
            {
                path: "payment/:orderId",
                name: "payment",
                component: () =>
                    import("@/views/payment/Index.vue"),
                meta: {
                    middleware: [authMiddleware],
                    pageTitle: "common.Payment and completion of the order",
                },
            },

        ],
    },
    ...auth,
    ...profile
];
